import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import GoogleReviews from "../../images/google-reviews.svg"
import GoogleImageDark from "../../images/google-reviews-dark.png"



const GoogleReview = (props) => {

    const data = useStaticQuery(graphql`
    query {
        allGoogleReviews(filter: {brand_name: {eq: "pittis"}}) {
            edges {
              node {
                averageRating
                brand_name
                totalReviewCount
              }
            }
        }
    }
  `);

    const reviews = data.allGoogleReviews.edges;
    let averageRating = reviews[0].node.averageRating;
    let totalReviewCount = reviews[0].node.totalReviewCount;
    if (props.averageRating) {
        averageRating = props.averageRating;
    }
    if (props.totalReviewCount) {
        totalReviewCount = props.totalReviewCount;
    }

    return (
        <React.Fragment>
            <div className="review-logo">
                {props.dark ?
                    <img src={GoogleImageDark} alt=""/>
                    :
                    <img src={GoogleReviews} alt="" width="74" height="30" />
                } 
            </div>
            <p>
                <strong>{(averageRating).toFixed(1)} out of 5 &nbsp;</strong>
                based on {totalReviewCount.toLocaleString()} reviews
            </p>
        </React.Fragment>
    );
};

export default GoogleReview;

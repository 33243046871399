import React, { useEffect, useState } from "react";
import Loadable from "@loadable/component"
import SEO from "../components/seo"
import Header from '../components/Header/Header';
import Banner from '../components/Home/Banner/HomeBanner';

const Footer = Loadable(() => import("../components/Footer/Footer"))
const About = Loadable(() => import("../components/Home/About/About"))
const NewsInsights = Loadable(() => import("../components/Home/NewsInsights/NewsInsights"))
const GetKnow = Loadable(() => import("../components/Home/GetKnow/GetKnow"))
const GlobalCount = Loadable(() => import("../components/Home/CountBlock/GlobalCount"))
const BookValuation = Loadable(() => import("../components/Home/BookValuation/BookValuation"))

const Modules = (props) => {

    const [renderComponent, setRenderComponent] = useState(false);
    useEffect(() => {
        window.addEventListener("mousemove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })

        const pageurl = typeof window !== 'undefined' ? window.location.pathname : ''
        if (window) {
            window.history.pushState({}, "", pageurl.split("?")[0]);
        }

    }, [])


    return (
        <React.Fragment>
            <SEO title={props.page.Meta_Title} description={props.page.Meta_Description} image={props.page.Banner_Image ? props.page.Banner_Image.url : ''} />

            <div className={props.page.Layout === 'Homepage' ? "home-header" : props.page.Layout === 'Default' ? 'wrapper area-guide-wrapper' : props.page.Layout === 'Staticpage' || props.page.Layout === 'Valuation' || props.page.Layout === 'Background_Image_Layout' || props.page.Layout === 'Calculator_or_Form' ? 'wrapper static-page' : ''}>
                <Header />

                {props.page.Layout === 'Homepage' && props.page.Banner_Image && props.page.Choose_Menu[0].URL === 'home' &&
                    <Banner {...props.page} />
                }

            </div>
            {
                props.modules && props.modules.map((module, i) => {
                    return (
                        <>
                            {module.Intro_Content &&
                                <About {...module} />
                            }

                            {module.Add_Service_Tile &&
                                <GetKnow {...module} />
                            }

                            {module.Select_Module && module.Select_Module.CTA_1_Link &&
                                <BookValuation {...module.Select_Module} />
                            }

                            {module.Stats_Title &&
                                <GlobalCount {...module} />
                            }

                            {module.Latest_News_Heading && module.Latest_News_Intro_Content &&
                                <NewsInsights {...module} />
                            }
                        </>
                    )
                })
            }
            <Footer search={props.page.Popular_Search} />
        </React.Fragment>
    )
}
export default Modules;